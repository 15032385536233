<template>
        <div>
            <div style="height:120px">

                <div  style="padding-top:5px; padding-left:17px;padding-right: 17px">

                    <div class="qmajorheader qalignmiddle qlargerfont">{{ langConv('Select Bookmakers') }}</div>
                    <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('This page automatically picks only the bookmakers that are licenced in your area') }}</div>
                    <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('However you can add or remove bookmakers from the list below') }}</div>
 

                </div>
            </div>
            <div style="padding:10px;">
                    
            </div>
            <div class="grid-x grid-padding-x size-up-small-6" style="text-align:center">
                <br />
                <div class="cell small-12">
                    <dropdown ocname="selectbookmaker" @bookmaker_values="setBKMValues" :ocnoitems="999" ocdefaultvalue="Select Bookmakers from list" :occloseonselect="false" :ocmultiselect="true" :ocdefaultselectedoptions="JSON.stringify($store.state.sb)" :ocshowlistonstart="true" :ocheightoflist="500"></dropdown>
                </div>
                    
                <div class="cell small-12" v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <div v-for="(error,i) in errors" style="color:red" :key="i">{{ error }}</div>
                </div>
                    
                <footer></footer>
                </div>
        </div>
    
</template>

<script>

    import dropdown from '../navigation/dropdown.vue'

    export default {
                data: function () {
            return {
                errors:[]
            }
        },
        components: {
            dropdown
        },
        created() {
           
            JSON.stringify(this.$store.state.sb)

        },

        methods:{
            setBKMValues: function (val) {
                this.$store.commit("setSelectedbookmakers", val) 

                 this.saveuser().then(resolve => {
                    //window.location.href = '/';
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>